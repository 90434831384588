@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  background-color: #ffffff;
  margin: 0;
  font-family: "OpenSansRegular", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/*
Font Weight	Numerical Weight
"Thin", "Hairline"	100
"Extra Light", "Ultra Light"	200
"Light", "Book"	300
"Normal", "Regular", "Roman", "Standard", "Plain"	400/Normal
"Medium", "Demi"	500
"Semi Bold", "Semi Bld", "Demi Bold", "Demi Bld"	600
"Bold", "Bld"	700/Bold
"Extra Bold", "Ultra Bold", "Extra Bld", "Ultra Bld"	800
"Black", "Heavy", "Ultra", "Fat", "Poster", "Ultra Black" 	 900
*/

@layer base {
  @font-face {
    font-family: "PoppinsThin";
    font-weight: 100;
    src: local("PoppinsThin"), url(./assets/fonts/Poppins-Thin.ttf) format("truetype");
  }
  @font-face {
    font-family: "PoppinsExtraLight";
    font-weight: 200;
    src: local("PoppinsExtraLight"), url(./assets/fonts/Poppins-ExtraLight.ttf) format("truetype");
  }
  @font-face {
    font-family: "PoppinsLight";
    font-weight: 300;
    src: local("PoppinsLight"), url(./assets/fonts/Poppins-Light.ttf) format("truetype");
  }
  @font-face {
    font-family: "PoppinsRegular";
    font-weight: 400;
    src: local("PoppinsRegular"), url(./assets/fonts/Poppins-Regular.ttf) format("truetype");
  }
  @font-face {
    font-family: "PoppinsMedium";
    font-weight: 500;
    src: local("PoppinsMedium"), url(./assets/fonts/Poppins-Medium.ttf) format("truetype");
  }
  @font-face {
    font-family: "PoppinsSemiBold";
    font-weight: 600;
    src: local("PoppinsSemiBold"), url(./assets/fonts/Poppins-SemiBold.ttf) format("truetype");
  }
  @font-face {
    font-family: "PoppinsBold";
    font-weight: 700;
    src: local("PoppinsBold"), url(./assets/fonts/Poppins-Bold.ttf) format("truetype");
  }
  @font-face {
    font-family: "PoppinsExtraBold";
    font-weight: 800;
    src: local("PoppinsExtraBold"), url(./assets/fonts/Poppins-ExtraBold.ttf) format("truetype");
  }
  @font-face {
    font-family: "PoppinsBlack";
    font-weight: 900;
    src: local("PoppinsBlack"), url(./assets/fonts/Poppins-Black.ttf) format("truetype");
  }
  @font-face {
    font-family: "Spartan";
    font-weight: 700;
    src: local("Spartan"), url(./assets/fonts/Spartan.ttf) format("truetype");
  }

  @font-face {
    font-family: "NewyearGoo";
    font-weight: 400;
    src: local("NewyearGoo"), url(./assets/fonts/Newyear-Goo.ttf) format("truetype");
  }
  @font-face {
    font-family: "TwCenMT";
    font-weight: 400;
    src: local("TwCenMT"), url(./assets/fonts/TwCenMT.ttf) format("truetype");
  }
  @font-face {
    font-family: "OpenSansLight";
    font-weight: 300;
    src: local("OpenSansLight"), url(./assets/fonts/OpenSans-Light.ttf) format("truetype");
  }
  @font-face {
    font-family: "OpenSansRegular";
    font-weight: 400;
    src: local("OpenSansRegular"), url(./assets/fonts/OpenSans-Regular.ttf) format("truetype");
  }
  @font-face {
    font-family: "OpenSansSemiBold";
    font-weight: 600;
    src: local("OpenSansSemiBold"), url(./assets/fonts/OpenSans-SemiBold.ttf) format("truetype");
  }
  @font-face {
    font-family: "OpenSansBold";
    font-weight: 700;
    src: local("OpenSansBold"), url(./assets/fonts/OpenSans-Bold.ttf) format("truetype");
  }
  @font-face {
    font-family: "OpenSansExtraBold";
    font-weight: 800;
    src: local("OpenSansExtraBold"), url(./assets/fonts/OpenSans-ExtraBold.ttf) format("truetype");
  }
}

.rainbow-gradient-text {
  background-image: linear-gradient(to left, violet, indigo, blue, green, #d2d20f, #eb9c0b, red);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.grill-gradient-text {
  background-image: linear-gradient(to right, #ffc200, #ed1106 65.71%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.shadow-box {
  box-shadow: 0px 4px 40px rgba(19, 19, 19, 0.25);
}
.shadow-box-2 {
  box-shadow: 2px 10px 28px rgba(75, 0, 129, 0.12);
}

.spinning {
  animation-name: spinning;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
@keyframes spinning {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.css-fade-left {
  -webkit-mask-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 50%);
  mask-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 50%);
}
